<template>
  <div class="content">
    <app-cells position="between">
      <app-cells :indent="false">
        <h1 class="title title--theme">Отчет о реализации</h1>
        <router-link v-if="userIsAdmin" :to="{ name: 'realizationreport-stat' }" class="btn btn--link">
          <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">
          <span>Статистика</span>
        </router-link>
      </app-cells>
      <v-select
        v-if="userIsAdmin"
        :reduce="item => item.id"
        :options="delegation_options"
        :filterable="true"
        :clearable="false"
        v-model="user_delegation_id"
        @input="onSetDelegation"
        label="name"
        placeholder="Выберите делегацию"
        class="select select--minw"
      >
        <template #open-indicator>
          <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
            <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
          </svg>
        </template>
        <template #option="option">
          <div class="select__item d-center">{{ option.name }}</div>
        </template>
        <template #selected-option="option">
          <div class="selected d-center">{{ option.name }}</div>
        </template>
        <template #no-options>Ничего не найдено</template>
      </v-select>
    </app-cells>
    <form @submit.prevent="onCheckForm">
      <h2 class="title title--medium title--indent">Общая информация</h2>
      <app-grid class="grid--indent">
        <template #item-1>
          <app-form-group
            required
            label="ФИО лица, ответственного за реализацию Программы на территории субъекта"
            label-for="full_name"
          >
            <app-input
              v-model="form.full_name"
              id="full_name"
              placeholder="Иванов Иван Иванович"
              @change.native="$v.form.full_name.$touch()"
              :error="$v.form.full_name.$error"
            />
            <template #error>
              <div v-if="$v.form.full_name.$dirty && !$v.form.full_name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Мобильный телефон лица, ответственного за реализацию Программы на территории субъекта" required>
            <app-phone
              v-model="form.phone"
              :error="$v.form.phone.$dirty && (!$v.form.phone.required || (form.phone === 0))"
              @change.native="$v.form.phone.$touch()"
              @paste.native.prevent
            />
            <template #error>
              <div v-if="$v.form.phone.$dirty && !$v.form.phone.required">Обязательное поле</div>
              <div v-if="$v.form.phone.$dirty && (form.phone === 0) && $v.form.phone.required">
                Неправильный формат номера
              </div>
            </template>
          </app-form-group>
          <app-form-group required label="Контактный адрес электронной почты лица, ответственного за реализацию Программы на территории субъекта" label-for="email">
            <app-input
              v-model="form.email"
              id="email"
              type="email"
              placeholder="mail@example.ru"
              :error="$v.form.email.$error"
              @change.native="$v.form.email.$touch()"
            />
            <template #error>
              <div v-if="$v.form.email.$dirty && !$v.form.email.email">Некорректный формат электронной почты</div>
              <div v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            required
            label="Ссылка на аккаунт в социальной сети «ВКонтакте» лица, ответственного за проведение регионального этапа"
            label-for="vk_link"
          >
            <app-input
              v-model="form.vk_link"
              id="vk_link"
              placeholder="https://vk.com/example"
              :error="$v.form.vk_link.$error"
              @change.native="$v.form.vk_link.$touch()"
            />
            <template #error>
              <div v-if="$v.form.vk_link.$dirty && !$v.form.vk_link.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group
            required
            label="Полные наименования всех организаций, которые вошли в договор о совместной реализации Программы на территории субъекта"
          >
            <app-form-group v-for="(item, index) in form.org_list" :key="index" class="form-group--min-indent">
              <app-input
                v-model.trim="item.name"
                placeholder="Введите название организации"
                :error="$v.form.org_list.$each[index].name.$error"
              />
              <button v-if="index > 0" @click="onDeleteItem('org_list', index)" class="link link--absolute link--absolute-custom" type="button"></button>
              <template #error>
                <div v-if="$v.form.org_list.$each.$dirty && !$v.form.org_list.$each[index].name.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-button size="link" class="btn--link-icon" @click="onCloneItem('org_list', {name: ''})" type="button">Добавить строку</app-button>
          </app-form-group>
<!--          <app-form-group-->
<!--            required-->
<!--            label="В каких всероссийских мероприятиях Программы приняли участие обучающиеся образовательных организаций вашего субъекта?"-->
<!--          >-->
<!--            <app-form-group v-for="(item, index) in form.list" :key="index" class="form-group&#45;&#45;min-indent">-->
<!--              <app-input-->
<!--                v-model.trim="item.name"-->
<!--                placeholder="Введите название организации"-->
<!--                :error="$v.form.list.$each[index].name.$error"-->
<!--              />-->
<!--              <button v-if="index > 0" @click="onDeleteItem('list', index)" class="link link&#45;&#45;absolute link&#45;&#45;absolute-custom" type="button"></button>-->
<!--              <template #error>-->
<!--                <div v-if="$v.form.list.$each.$dirty && !$v.form.list.$each[index].name.required">Обязательное поле</div>-->
<!--              </template>-->
<!--            </app-form-group>-->
<!--            <app-button size="link" class="btn&#45;&#45;link-icon" @click="onCloneItem('list', {name: ''})" type="button">Добавить строку</app-button>-->
<!--          </app-form-group>-->
        </template>
      </app-grid>
<!--      <h2 class="title title&#45;&#45;medium title&#45;&#45;indent">Участие представителей региональных дирекций</h2>-->
<!--      <app-form-group>-->
<!--        <app-checkbox-->
<!--          v-model="form.the_installation_meeting_regional_directions_1"-->
<!--          label="Установочное совещание региональных дирекций фестиваля ПОО (2024г., г. Ханты-Мансийск)"-->
<!--        />-->
<!--      </app-form-group>-->
<!--      <app-form-group>-->
<!--        <app-checkbox-->
<!--          v-model="form.the_installation_meeting_regional_directions_2"-->
<!--          label="Установочное совещание региональных дирекций фестиваля ООВО (2024г., г. Пермь)"-->
<!--        />-->
<!--      </app-form-group>-->
      <h2 class="title title--medium title--indent">Информация о региональном фестивале</h2>
      <app-grid class="grid--indent">
        <template #item-1>
          <app-form-group
            required
            label="Укажите формат проведения регионального фестиваля"
            label-for="format"
          >
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>Очный / без зрителей / онлайн и тп.</span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.format"
              id="format"
              placeholder="Введите значение"
              @change.native="$v.form.format.$touch()"
              :error="$v.form.format.$error"
            />
            <template #error>
              <div v-if="$v.form.format.$dirty && !$v.form.format.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            required
            label="Укажите полное наименование регионального фестиваля в соответствии с утвержденным положением о региональном фестивале"
            label-for="name"
          >
            <app-input
              v-model="form.name"
              id="name"
              placeholder="Введите значение"
              @change.native="$v.form.name.$touch()"
              :error="$v.form.name.$error"
            />
            <template #error>
              <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Дата начала регионального фестиваля" label-for="date_start">
            <app-input
              v-model="form.date_start"
              v-mask="'99.99.9999'"
              :error="$v.form.date_start.$error"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              id="date_start"
              @paste.native.prevent
            />
            <template #error>
              <div v-if="$v.form.date_start.$dirty && !$v.form.date_start.underscorePresent">Заполните поле полностью</div>
              <div v-if="$v.form.date_start.$dirty && !$v.form.date_start.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Дата окончания регионального фестиваля" label-for="date_end">
            <app-input
              v-model="form.date_end"
              v-mask="'99.99.9999'"
              :error="$v.form.date_end.$error"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              id="date_end"
              @paste.native.prevent
            />
            <template #error>
              <div v-if="$v.form.date_end.$dirty && !$v.form.date_end.underscorePresent">Заполните поле полностью</div>
              <div v-if="$v.form.date_end.$dirty && !$v.form.date_end.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            required
            label="Количество образовательных организаций, которые приняли участие в региональном фестивале «Студенческая весна» "
            label-for="edu_org_count"
          >
            <app-input
              v-model.number="form.edu_org_count"
              id="edu_org_count"
              type="number"
              min="0"
              placeholder="Введите значение"
              @change.native="$v.form.edu_org_count.$touch()"
              :error="$v.form.edu_org_count.$error"
            />
            <template #error>
              <div v-if="$v.form.edu_org_count.$dirty && !$v.form.edu_org_count.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            required
            label="Перечислите крупных партнеров и спонсоров регионального фестиваля (за исключением органов исполнительной власти). Если таковых нет, напишите «нет»."
          >
            <app-form-group v-for="(item, index) in form.sponsors" :key="index" class="form-group--min-indent">
              <app-input
                v-model.trim="item.name"
                placeholder="Введите название организации"
                :error="$v.form.sponsors.$each[index].name.$error"
              />
              <button v-if="index > 0" @click="onDeleteItem('sponsors', index)" class="link link--absolute link--absolute-custom" type="button"></button>
              <template #error>
                <div v-if="$v.form.sponsors.$each.$dirty && !$v.form.sponsors.$each[index].name.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-button size="link" class="btn--link-icon" @click="onCloneItem('sponsors', {name: ''})" type="button">Добавить строку</app-button>
          </app-form-group>
          <app-form-group
            required
            label="Участвуют ли в организации и проведении регионального фестиваля органы исполнительной власти в области образования?"
            label-for="take_vlast"
          >
            <app-input
              v-model="form.take_vlast"
              id="take_vlast"
              placeholder="Введите значение"
              @change.native="$v.form.take_vlast.$touch()"
              :error="$v.form.take_vlast.$error"
            />
            <template #error>
              <div v-if="$v.form.take_vlast.$dirty && !$v.form.take_vlast.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            required
            label="Участвуют ли в организации и проведении регионального фестиваля органы исполнительной власти, реализующие государственную молодёжную политику?"
            label-for="take_molodej"
          >
            <app-input
              v-model="form.take_molodej"
              id="take_molodej"
              placeholder="Введите значение"
              @change.native="$v.form.take_molodej.$touch()"
              :error="$v.form.take_molodej.$error"
            />
            <template #error>
              <div v-if="$v.form.take_molodej.$dirty && !$v.form.take_molodej.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            required
            label="Адрес группы в социальной сети «Вконтакте», посвященной региональному фестивалю (скопируйте из адресной строки браузера) и другие информационные каналы"
          >
            <app-form-group v-for="(item, index) in form.social" :key="index" class="form-group--min-indent">
              <app-input
                v-model.trim="item.name"
                placeholder="https://vk.com/example"
                :error="$v.form.social.$each[index].name.$error"
              />
              <button v-if="index > 0" @click="onDeleteItem('social', index)" class="link link--absolute link--absolute-custom" type="button"></button>
              <template #error>
                <div v-if="$v.form.social.$each.$dirty && !$v.form.social.$each[index].name.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-button size="link" class="btn--link-icon" @click="onCloneItem('social', {name: ''})" type="button">Добавить строку</app-button>
          </app-form-group>
          <app-form-group
            required
            label="ФИО пресс-секретаря регионального фестиваля или SMM-менеджера региональной группы фестиваля в социальной сети «ВКонтакте»"
            label-for="press_full_name"
          >
            <app-input
              v-model="form.press_full_name"
              id="press_full_name"
              placeholder="Иванов Иван Иванович"
              @change.native="$v.form.press_full_name.$touch()"
              :error="$v.form.press_full_name.$error"
            />
            <template #error>
              <div v-if="$v.form.press_full_name.$dirty && !$v.form.press_full_name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Телефон пресс-секретаря регионального фестиваля или SMM-менеджера региональной группы фестиваля в социальной сети «ВКонтакте»" required>
            <app-phone
              v-model="form.press_phone"
              :error="$v.form.press_phone.$dirty && (!$v.form.press_phone.required || (form.press_phone === 0))"
              @change.native="$v.form.press_phone.$touch()"
              @paste.native.prevent
            />
            <template #error>
              <div v-if="$v.form.press_phone.$dirty && !$v.form.press_phone.required">Обязательное поле</div>
              <div v-if="$v.form.press_phone.$dirty && (form.press_phone === 0) && $v.form.press_phone.required">
                Неправильный формат номера
              </div>
            </template>
          </app-form-group>
          <app-form-group required label="Электронная почта пресс-секретаря регионального фестиваля или SMM-менеджера региональной группы фестиваля в социальной сети «ВКонтакте»" label-for="press_email">
            <app-input
              v-model="form.press_email"
              id="press_email"
              type="email"
              placeholder="mail@example.ru"
              :error="$v.form.press_email.$error"
              @change.native="$v.form.press_email.$touch()"
            />
            <template #error>
              <div v-if="$v.form.press_email.$dirty && !$v.form.press_email.email">Некорректный формат электронной почты</div>
              <div v-if="$v.form.press_email.$dirty && !$v.form.press_email.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            required
            label="Ссылка на аккаунт в социальной сети «ВКонтакте» пресс-секретаря регионального фестиваля или SMM-менеджера региональной группы фестиваля в социальной сети «ВКонтакте»"
            label-for="press_vk_link"
          >
            <app-input
              v-model="form.press_vk_link"
              id="vk_link"
              placeholder="https://vk.com/example"
              :error="$v.form.press_vk_link.$error"
              @change.native="$v.form.press_vk_link.$touch()"
            />
            <template #error>
              <div v-if="$v.form.press_vk_link.$dirty && !$v.form.press_vk_link.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            required
            label="Ссылки на публикации в СМИ о проведении регионального этапа мероприятий программы (скопируйте из адресной строки браузера ссылки на конкретные публикации)"
          >
            <app-form-group v-for="(item, index) in form.smi" :key="index" class="form-group--min-indent">
              <app-input
                v-model.trim="item.name"
                placeholder="https://www.example.ru"
                :error="$v.form.smi.$each[index].name.$error"
              />
              <button v-if="index > 0" @click="onDeleteItem('smi', index)" class="link link--absolute link--absolute-custom" type="button"></button>
              <template #error>
                <div v-if="$v.form.smi.$each.$dirty && !$v.form.smi.$each[index].name.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-button size="link" class="btn--link-icon" @click="onCloneItem('smi', {name: ''})" type="button">Добавить строку</app-button>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label="Участники мероприятий программы в 2023-2024 г" required>
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <button class="tooltip__link" @click="onDownloadFile(`${documents_url}report/participapts.xlsx', 'Образец списка участников мероприятий программы`)">Скачать образец списка участников мероприятий программы</button>
                </template>
              </app-tooltip>
            </template>
            <app-uploader
              v-model="form.participants_file"
              :extensions="['image/*', 'application/pdf', '.docx', '.xlsx', '.doc', '.xls']"
            />
            <template #error>
              <div v-if="$v.form.participants_file.$dirty && !$v.form.participants_file.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Список образовательных организаций, которые приняли участие в региональном фестивале «Студенческая весна»" required>
            <app-uploader
              v-model="form.list_organizations_in_regional_festival"
              :extensions="['image/*', 'application/pdf', '.docx', '.xlsx', '.doc', '.xls']"
            />
            <template #error>
              <div v-if="$v.form.list_organizations_in_regional_festival.$dirty && !$v.form.list_organizations_in_regional_festival.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Список образовательных организаций, которые провели отборочный этап" required>
            <app-uploader
              v-model="form.list_organizations_in_qualifying_stage"
              :extensions="['image/*', 'application/pdf', '.docx', '.xlsx', '.doc', '.xls']"
            />
            <template #error>
              <div v-if="$v.form.list_organizations_in_qualifying_stage.$dirty && !$v.form.list_organizations_in_qualifying_stage.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="План мероприятий" required>
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <button class="tooltip__link" @click="onDownloadFile(`${documents_url}report/plan.xlsx`, 'Образец плана мероприятий')">Скачать образец плана мероприятий</button>
                </template>
              </app-tooltip>
            </template>
            <app-uploader
              v-model="form.action_plan"
              :extensions="['image/*', 'application/pdf', '.docx', '.xlsx', '.doc', '.xls']"
            />
            <template #error>
              <div v-if="$v.form.action_plan.$dirty && !$v.form.action_plan.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Список использованных музыкальных произведений РАО" required>
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <button class="tooltip__link" @click="onDownloadFile(`${documents_url}report/rao_vois.xlsx`, 'Образец списка музыкальных произведений РАО')">Скачать образец списка музыкальных произведений РАО</button>
                </template>
              </app-tooltip>
            </template>
            <app-uploader
              v-model="form.RAO_musical_works_used"
              :extensions="['image/*', 'application/pdf', '.docx', '.xlsx', '.doc', '.xls']"
            />
            <template #error>
              <div v-if="$v.form.RAO_musical_works_used.$dirty && !$v.form.RAO_musical_works_used.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Список использованных музыкальных произведений ВОИС" required>
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <button class="tooltip__link" @click="onDownloadFile(`${documents_url}report/rao_vois.xlsx`, 'Образец списка музыкальных произведений ВОИС')">Скачать образец списка музыкальных произведений ВОИС</button>
                </template>
              </app-tooltip>
            </template>
            <app-uploader
              v-model="form.VOIS_musical_works_used"
              :extensions="['image/*', 'application/pdf', '.docx', '.xlsx', '.doc', '.xls']"
            />
            <template #error>
              <div v-if="$v.form.VOIS_musical_works_used.$dirty && !$v.form.VOIS_musical_works_used.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <h2 class="title title--medium title--indent">Обратная связь</h2>
      <app-grid>
        <template #item-1>
          <app-form-group
            required
            label="Опишите трудности, возникшие при реализации Программы в субъекте и возможную помощь в их решения при реализации Программы в дальнейшем"
            label-for="trouble"
          >
            <app-textarea
              v-model.number="form.trouble"
              id="trouble"
              placeholder="Введите значение"
              @change.native="$v.form.trouble.$touch()"
              :error="$v.form.trouble.$error"
            />
            <template #error>
              <div v-if="$v.form.trouble.$dirty && !$v.form.trouble.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            required
            label="Опишите трудности, возникшие при взаимодействии с Федеральной дирекцией Программы и пути их решения"
            label-for="trouble_fed"
          >
            <app-textarea
              v-model.number="form.trouble_fed"
              id="last_name"
              placeholder="Введите значение"
              @change.native="$v.form.trouble_fed.$touch()"
              :error="$v.form.trouble_fed.$error"
            />
            <template #error>
              <div v-if="$v.form.trouble_fed.$dirty && !$v.form.trouble_fed.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group
            required
            label="Ваши предложения по развитию Программы"
            label-for="suggestions"
          >
            <app-textarea
              v-model.number="form.suggestions"
              id="suggestions"
              placeholder="Введите значение"
              @change.native="$v.form.suggestions.$touch()"
              :error="$v.form.suggestions.$error"
            />
            <template #error>
              <div v-if="$v.form.suggestions.$dirty && !$v.form.suggestions.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button
          type="submit"
          ref="submit"
          :disabled="$v.form.$error"
        >
          Сохранить
        </app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'
import { dateFormatting } from '@/helpers'
import {
  getRealizationReport,
  postRealizationReport,
  patchRealizationReport,
  getDelegations,
  patchUser,
  getFile
} from '@/http'
import IconTooltip from '@/components/icons/IconTooltip'

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'RealizationReport',
  components: {
    IconTooltip,
  },
  data() {
    return {
      form: {
        org_list:[
          {
            name: ''
          }
        ],
        sponsors:[
          {
            name: ''
          }
        ],
        smi:[
          {
            name: ''
          }
        ],
        social:[
          {
            name: ''
          }
        ],
      },
      new_report: false,
      delegation_options: [],
      user_delegation_id: null,
      documents_url: process.env.VUE_APP_STATIC_DOCUMENTS_URL
    }
  },
  validations: {
    form: {
      full_name: { required },
      press_full_name: { required },
      format: { required },
      edu_org_count: { required },
      take_vlast: { required },
      take_molodej: { required },
      name: { required },
      suggestions: { required },
      trouble: { required },
      trouble_fed: { required },
      phone: { required },
      press_phone: { required },
      vk_link: { required },
      press_vk_link: { required },
      email: { required, email },
      press_email: { required, email },
      date_start: { required, underscorePresent },
      date_end: { required, underscorePresent },
      participants_file: { required },
      RAO_musical_works_used: { required },
      VOIS_musical_works_used: { required },
      list_organizations_in_regional_festival: { required },
      list_organizations_in_qualifying_stage: { required },
      action_plan: { required },
      org_list: {
        $each: {
          name: { required },
        }
      },
      sponsors: {
        $each: {
          name: { required },
        }
      },
      smi: {
        $each: {
          name: { required },
        }
      },
      social: {
        $each: {
          name: { required },
        }
      },
    }
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
  },
  created() {
    this.fetchData()
    setTimeout(() => {
      if (this.userIsAdmin) {
        getDelegations()
          .then(response => {
            this.delegation_options = response.data
            this.user_delegation_id = this.$store.state.delegation
            if (this.$route.params.delegation_id) {
              this.user_delegation_id = this.$route.params.delegation_id
              this.onSetDelegation()
            }
          })
      }
    }, 200)
  },
  methods: {
    fetchData() {
      getRealizationReport()
        .then(response => {
          this.form = {
            ...response.data,
            date_start: response.data.date_start ? dateFormatting(response.data.date_start, 'iso-to-normal-small') : '',
            date_end: response.data.date_end ? dateFormatting(response.data.date_end, 'iso-to-normal-small') : '',
          }
        })
        .catch(() => {
          this.new_report = true
          this.form = {
            org_list:[
              {
                name: ''
              }
            ],
            sponsors:[
              {
                name: ''
              }
            ],
            smi:[
              {
                name: ''
              }
            ],
            social:[
              {
                name: ''
              }
            ],
            press_phone: '',
            phone: '',
            participants_file: {},
            RAO_musical_works_used: {},
            VOIS_musical_works_used: {},
            list_organizations_in_regional_festival: {},
            list_organizations_in_qualifying_stage: {},
            action_plan: {},
          }
        })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (this.new_report) {
        postRealizationReport(this.normalizeForm())
          .finally(() => {
            this.$refs.submit.preload = false
          })
          .then(() => {
            this.fetchData()
            this.$notify({
              type: 'success',
              text: 'Отчет о реализации добавлен'
            })
          })
      } else {
        patchRealizationReport(this.normalizeForm())
          .finally(() => {
            this.$refs.submit.preload = false
          })
          .then(() => {
            this.fetchData()
            this.$notify({
              type: 'success',
              text: 'Отчет о реализации изменен'
            })
          })
      }
    },
    normalizeForm() {
      const form = {...this.form}
      form.date_start = dateFormatting(form.date_start, 'normal-to-iso-small')
      form.date_end = dateFormatting(form.date_end, 'normal-to-iso-small')
      form.participants_file = form.participants_file.id
      form.RAO_musical_works_used = form.RAO_musical_works_used.id
      form.VOIS_musical_works_used = form.VOIS_musical_works_used.id
      form.list_organizations_in_regional_festival = form.list_organizations_in_regional_festival.id
      form.list_organizations_in_qualifying_stage = form.list_organizations_in_qualifying_stage.id
      form.action_plan = form.action_plan.id
      return form
    },
    onSetDelegation() {
      this.$store.commit('setDelegation', this.user_delegation_id)
      patchUser({ delegation: this.user_delegation_id })
        .then(response => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Делегация успешно изменена'
          })
          this.fetchData()
          this.$store.commit('setDelegationHash', response.data.delegation_hash)
        })
    },
    onCloneItem(formField, obj) {
      this.form[formField].push(obj)
      this.$v.form.$touch()
    },
    onDeleteItem(formField, index) {
      this.form[formField].splice(index, 1)
    },
    onDownloadFile(url, name) {
      getFile(url)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${name}.xlsx`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
  }
}
</script>

